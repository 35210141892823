exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-mdx": () => import("./../../../src/pages/faq.mdx" /* webpackChunkName: "component---src-pages-faq-mdx" */),
  "component---src-pages-games-[id]-js": () => import("./../../../src/pages/games/[id].js" /* webpackChunkName: "component---src-pages-games-[id]-js" */),
  "component---src-pages-games-index-js": () => import("./../../../src/pages/games/index.js" /* webpackChunkName: "component---src-pages-games-index-js" */),
  "component---src-pages-games-install-js": () => import("./../../../src/pages/games/install.js" /* webpackChunkName: "component---src-pages-games-install-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-mdx": () => import("./../../../src/pages/resources.mdx" /* webpackChunkName: "component---src-pages-resources-mdx" */),
  "component---src-pages-ui-theme-js": () => import("./../../../src/pages/ui/theme.js" /* webpackChunkName: "component---src-pages-ui-theme-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */)
}

